import React, { useState } from 'react';

const CircleUpload: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const [user, setUser] = useState('');

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const file = event.dataTransfer.items[i].getAsFile();
        if (file) {
          setFileName(file.name);

          const formData = new FormData();
          formData.append('blackboxes', file);
          formData.append('user', user);

          const xhr = new XMLHttpRequest();
          xhr.open('POST', 'http://7s01:8021/upload');

          xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
              const percent = Math.round((event.loaded / event.total) * 100);
              setUploadProgress(percent);
            }
          });

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              setLoading(false);
              setTimeout(function() {
                setUploadProgress(0);
                setFileName("");
              }, 2000);
            }
          };

          xhr.send(formData);
          setLoading(true);
        }
      }
    }
  };

  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser(e.target.value);
  };

  const render_user_input = () => {
    return (
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <br />
            <img width="32px" height="32px" src="robot_walk.gif" alt='walking robot'/>
            <input type="text" value={user}
              placeholder="Username to tag or tags all"
              onChange={handleUserChange} />
            <img width="32px" height="32px" src="music_robot.gif" alt='dancing robot'/>
        </div>
        )
  }

  return (
    <div>
      {render_user_input()}
      <div 
        style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}
        onDragOver={(event: React.DragEvent<HTMLDivElement>) => event.preventDefault()} 
        onDrop={handleDrop}
      >
        <div 
          style={{
            position: "relative",
            width: "150px", 
            height: "150px", 
            borderRadius: "75px", 
            backgroundColor: loading ? "orange" : uploadProgress === 100 ? "green" : "blue",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            overflow: "hidden"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>{uploadProgress > 0 ? fileName : "DROP 7sb here"}</div>
            { uploadProgress > 0 && (
            <div style={{fontSize: "14px", fontWeight: "normal"}}>
              {uploadProgress}%
            </div>
            )}
          </div>

          <svg viewBox="0 0 36 36" style={{position: "relative", zIndex: 1}}>
            <path
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke="#fff"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke={loading ? "orange" : "green"}
              strokeWidth="3"
              strokeLinecap="round"
              style={{strokeDasharray: `${uploadProgress}, 100`}}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CircleUpload;
